import { useEffect, useState } from 'react';
import styles from './navbar-mobile.module.css';
import { useRouter } from 'next/router';

export function NavBarMobile() {
	const [showMenu, setShowMenu] = useState(false);
	const router = useRouter();
	useEffect(() => {setShowMenu(false);}, [router]);
	  return (
		<div className="showInMobile">
			<div className={styles.mobileNavContainer}  style={{display: `${showMenu ? 'block' : 'none'}`}}>
				<nav className={styles.navbar}>

					<div style={{fontSize: '24px',fontWeight: 600, marginTop: '8px'}}>Transport og Reiser</div>
					<ul>
						<li><a href="/sok?q=Barnevogn">Barnevogn</a></li>
						<li><a href="/sok?q=Bilstol">Bilstol</a></li>
						<li><a href="/sok?q=Bæreseler">Bæreseler</a></li>
					</ul>
					<div style={{fontSize: '24px',fontWeight: 600, marginTop: '8px'}}>Sikkerhet</div>
					<ul>
						<li><a href="/sok?q=Babycall">Babycall</a></li>
						<li><a href="/sok?q=Sikkerhetsgrinder">Sikkerhetsgrinder</a></li>
					</ul>
					<div style={{fontSize: '24px',fontWeight: 600, marginTop: '8px'}}>Soving</div>
					<ul>
						<li><a href="/sok?q=Seng">Seng</a></li>
						<li><a href="/sok?q=Madrasser">Madrasser</a></li>
						<li><a href="/sok?q=Sengetøy">Sengetøy</a></li>
						<li><a href="/sok?q=Smokker">Smokker</a></li>
						<li><a href="/sok?q=Sovepose">Sovepose</a></li>
					</ul>
					<div style={{fontSize: '24px',fontWeight: 600, marginTop: '8px'}}>Mat og Ernæring</div>
					<ul>
						<li><a href="/sok?q=Amming">Amming</a></li>
						<li><a href="/sok?q=Flasker">Flasker</a></li>
						<li><a href="/sok?q=Matstoler">Matstoler</a></li>
					</ul>
					<div style={{fontSize: '24px',fontWeight: 600, marginTop: '8px'}}>Helse og Velvære</div>
					<ul>
						<li><a href="/sok?q=Badeutstyr">Badeutstyr</a></li>
						<li><a href="/sok?q=Stell">Stell</a></li>
						<li><a href="/sok?q=Pleieprodukter">Pleieprodukter</a></li>
					</ul>					
					<div style={{fontSize: '24px',fontWeight: 600, marginTop: '8px'}}>Annet</div>
					<ul>
						<li><a href="/produkter/merkelapper">Merkelapper</a></li>	
						<li><a href="/artikler/dette-trenger-du">Sjekkliste</a></li>	
					</ul>
										
				</nav>
		  	</div>
		  	<button className={styles.fab} onClick={() => {
				setShowMenu(!showMenu);
			}}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
					<title>menu</title>
					{!showMenu ? 
					<path fill="#fff" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
					:
					<path fill="#fff" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" />
					}
				</svg>
			</button>
		</div>

  );
}